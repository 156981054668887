import { IndeterminateCheckbox } from '../components/common/IndeterminateCheckbox';
import { Table } from 'reactstrap';
import { DefaultColumnFilter } from '../components/common/ColumnFilters';
import { RowDragHandleCell } from '../components/common/Cell';

export const getColumnId = (columnProperties) => {
    const columnId = columnProperties.accessorKey ?? columnProperties.id;
    return columnId.replace('.', '_');
}

export const getDefaultPageSize = (sizes) => {
    const defaultSize = 5;
    if (!sizes || sizes.length === 0) {
        return defaultSize;
    }

    return sizes[0];
}

export const getDefaultHiddenColumnsArray = (columns) => {
    if (columns?.length > 0) {
        const hiddenObjects = columns.filter(i => i.default === false);
        return hiddenObjects?.length > 0 ? hiddenObjects.map(o => getColumnId(o)) : [];
    }
    return [];
}

export const getHiddenColumnsObject = (hiddenColumns) => {
    //console.log(hiddenColumns);
    if (hiddenColumns && Array.isArray(hiddenColumns)) {
        return hiddenColumns.reduce((acc, current) => {
            acc[current.replace('.', '_')] = false;
            return acc;
        }, {});
    }

    return {}
}

export const getCheckboxColumnDef = (selectAllDisabled = false, rowSelectionDisabled = false) => {
    return {
        accessorKey: 'select',
        header: ({ table }) => (
            <div className="d-flex justify-content-center">
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllPageRowsSelected(),
                        disabled: selectAllDisabled,
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllPageRowsSelectedHandler(),
                        hideIcon: true
                    }}
                />
            </div>
        ),
        enableSorting: false, // disable sorting for this column
        enableColumnFilter: false,
        enableGlobalFilter: false,
        size: 50,
        cell: ({ row }) => (
            <div className="px-1 d-flex justify-content-center">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect() || rowSelectionDisabled,
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            </div>
        ),
    }
}

export const getActionsColumnDef = (cellFunction) => {
    return {
        header: 'Actions',
        accessorKey: 'actions',
        className: 'action-btn-col',
        enableSorting: false, // disable sorting for this column
        enableColumnFilter: false, // disable column filtering
        enableGlobalFilter: false, // disable global filtering
        cell: ({ row: { original } }) => cellFunction(original)
    }
}

export const getMoveColumnDef = () => {
    return {
        header: 'Move',
        id: 'move',
        //className: 'w-50px',
        size: 50,
        cellOld: ({ row }) => <div className="px-1 d-flex">
            <RowDragHandleCell rowId={row.id} />
        </div>,
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />
    }
}

export const CustomTable = ({
    table,
    render,
    tableClasses,
    size = "sm",
    enableSorting = true,
    showColumnFilters = true,
    filterChanged
}) => {
    const columnHeaderClasses = showColumnFilters ? "pt-2 pb-2" : "";

    const onFilterChanged = (value) => {
        if (filterChanged) {
            filterChanged(value);
        }
    }

    //console.log(table.getRowModel());
    return (
        <Table size={size} hover responsive bordered className={tableClasses}>
            <thead className="bg-light">{table.getHeaderGroups().map(hg => {
                //console.log('hg', hg);
                return <tr key={hg.id}>{hg.headers.map(header => {
                    //console.log('header', header);
                    if (enableSorting) {
                        return (
                            <th key={header.id} colSpan={header.colSpan} className={!showColumnFilters || (showColumnFilters && header.column.getCanFilter()) ? "" : "align-top"}>
                                {header.isPlaceholder ? null : (
                                    <>
                                        <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer ' + columnHeaderClasses
                                                    : columnHeaderClasses,
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {render(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted()] ?? null}
                                        </div>
                                        {showColumnFilters && header.column.getCanFilter() ? (
                                            <div>
                                                <DefaultColumnFilter column={header.column} filterChanged={onFilterChanged} />
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </th>
                        )
                    }
                    else {
                        return <th key={header.id} colSpan={header.colSpan}>{render(header.column.columnDef.header, header.getContext())}</th>;
                    }
                })}</tr>;
            })}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => {
                    return <tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                            return <td className={cell.column.columnDef.className} key={cell.id}>
                                {render(cell.column.columnDef.cell, cell.getContext())}
                            </td>;
                        })}
                    </tr>;
                })}
            </tbody>
        </Table>
    );
}

// TODO - for some reason this is not working if not in same file than the table itself
// Do reseach when time.
//export const DraggableRow = ({ row, render }) => {
//    const { transform, transition, setNodeRef, isDragging } = useSortable({
//        id: row.original.userId,
//    })

//    const style = {
//        transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
//        transition: transition,
//        opacity: isDragging ? 0.8 : 1,
//        zIndex: isDragging ? 1 : 0,
//        position: 'relative',
//    }
//    return (
//        // connect row ref to dnd-kit, apply important styles
//        <tr ref={setNodeRef} style={style}>
//            {row.getVisibleCells().map(cell => (
//                <td key={cell.id} style={{ width: cell.column.getSize() }}>
//                    {render(cell.column.columnDef.cell, cell.getContext())}
//                </td>
//            ))}
//        </tr>
//    )
//}