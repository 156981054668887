import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Action, EntityType } from '../../helpers/enums';
import EntityLayout from './EntityLayout';
import { ItemBaseColumns, ItemColumns } from '../workspace/Columns';
import workspaceActions from '../../actions/workspaceActions';
import { isStorageOwner } from '../../helpers/commonHelpers';
import ItemFlagsModal from '../workspace/modals/ItemFlagsModal';

export function ItemSearch ({
    type, 
    setSelectedRows, 
    refreshItems, 
    onEntitiesRefreshed,
    msalInstance
}) {
    let currentUser = useSelector(state => state.application.user);
    let selectedStorage = useSelector(state => state[type].activeItem);
    let categories = useSelector(state => state[type].storageCategories);
    let noItemsFound = useSelector(state => state[type].noItemsFound);
    let applicationFlags = useSelector(state => state.application.flags);
    const dispatch = useDispatch();

    const [enabledActions, setEnabledActions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showFlagModal, setShowFlagModal] = useState(false);
    const [refresh, setRefresh] = useState([]);
    
    const setValues = (entity, newValues, action) => {
        //console.log('newValues', newValues); //console.log('action', action);console.log('entity', entity);
        if (action && action === Action.EDIT){
            entity.code = newValues.code;
            entity.description = newValues.description;
            entity.internalNotes = newValues.internalNotes;
            entity.internalId = newValues.internalId;
        }
        entity.elementStatus = newValues.elementStatus;
        entity.categoryId = newValues.categoryId;
        if (newValues.category || !entity.categoryId){
            entity.category = newValues.category;
        }
        else if (categories && categories.length > 0){
            var selectedCategory = categories.filter(c => c.id === newValues.categoryId);
            if (selectedCategory && selectedCategory.length > 0){
                entity.category = selectedCategory[0].name;
            }
        }
        if (!action || action !== Action.EDIT){ // The element status or flags do not change when user is updating an item.
            entity.flags = newValues.flags;
            entity.flagSymbols = newValues.flags?.length > 0 && applicationFlags?.length > 0 ?
                applicationFlags.filter(f => (newValues.flags.map(n => n.flagId)).includes(f.id)).map(a => a.symbol).join(" ")
                : "";
        }
    }

    const toggleFlagModal = (selectedItem) => {
        if (selectedItem?.id > 0) {
            setSelectedItem(selectedItem);
        }
        setShowFlagModal(!showFlagModal);
    }

    useEffect(() => {
        if (currentUser) {
            let userActions = [{ action: Action.VIEW },
                { action: Action.SETTINGS },
                { action: Action.FLAG, onClick: toggleFlagModal }];
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            if (isStorageOwner(currentUser, selectedStorageId)) {
                userActions.push({ action: Action.CREATE });
                userActions.push({ action: Action.EDIT });
                userActions.push({ action: Action.COPY });
                userActions.push({ action: Action.DELETE });
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log('refreshItems', refreshItems);
        if (refreshItems?.length > 0) {
            setRefresh(refreshItems);
        }
    }, [refreshItems]);

    const onItemsUpdated = (items) => {
        if (items?.length > 0 && noItemsFound) {
            dispatch(workspaceActions.setNoItemsFound(false));
        }
    }

    const onItemFlagsUpdated = (itemId, flags) => {
        //console.log('itemId, flags', itemId, flags);
        if (itemId > 0) {
            setRefresh([itemId]);
        }
    }
    
    return (
        <EntityLayout
            type={type}
            entityType={EntityType.ITEM}
            columns={ItemColumns()}
            setValues={setValues}
            enabledActions={enabledActions}
            tableStyle="action-3"
            onSelectedEntities={setSelectedRows}
            categories={categories}
            flags={applicationFlags}
            logColumns={ItemBaseColumns()}
            refreshEntities={refresh}
            onEntitiesRefreshed={onEntitiesRefreshed}
            onEntitiesUpdated={onItemsUpdated}
            msalInstance={msalInstance}
        >
            <ItemFlagsModal
                isOpen={showFlagModal}
                toggle={toggleFlagModal}
                selectedItem={selectedItem}
                onFlagsUpdated={onItemFlagsUpdated}
                msalInstance={msalInstance}
                currentUser={currentUser}
            />
        </EntityLayout>
    )
}