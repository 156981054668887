import React, { forwardRef } from 'react';
import { Input } from 'reactstrap';
import { objectToString } from '../../helpers/commonHelpers';
import { CustomSimpleEditor } from './CustomEditor';
import { UserInputType } from '../../helpers/enums';

export const CalculatedValueInput = forwardRef(
    (props, ref) => {
        //console.log('props', props);
        const { value, onChange, type, ...properties } = props;
        var inputValue = typeof value === "object" ? objectToString(value): value.toString();

        //console.log('type', type);
        if (type === UserInputType.STRING || type === UserInputType.STRING_ARRAY || type === UserInputType.STRING_DICT) {
            return (<div className="container-editor default form-control disabled">
                <CustomSimpleEditor {...properties}
                //id="criterion"
                value={inputValue}
                disabled={true}
                />
            </div>);
        }

        if (typeof value === "object") {
            const propertyCount = Object.keys(value).length;
            const rows = propertyCount > 9 ? 10 : propertyCount + 2;

            return (<Input {...properties}
                type="textarea"
                ref={ref}
                value={inputValue}
                rows={rows}
                disabled={true}
            />)
        }

        return (<Input {...properties}
            type="text"
            ref={ref}
            value={inputValue}
            disabled={true}
        />)
    }
)