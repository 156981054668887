import React, { useState } from 'react';
import { Container, FormGroup, Label, Input, Button } from 'reactstrap';
import ListHeader from './ListHeader';
import { Role, PublishingRole } from './../../helpers/enums';
import { parseToNumber } from './../../helpers/commonHelpers';

function allChecked(list, restricted, entitiesForUser){
    let isChecked = true; //console.log('allChecked', list, entitiesForUser);
    Object.keys(list).forEach(checkbox => { 
        //console.log('value for ' + checkbox, list[checkbox]);
        if (restricted) {
            if (entitiesForUser.includes(parseToNumber(checkbox)) && !list[checkbox]) isChecked = false;
        }
        else {
            if (!list[checkbox]) isChecked = false;
        }
    });
    //console.log('isChecked', isChecked);
    return isChecked;
}

function CheckboxList({
    title,
    listItems,
    onItemSelected,
    selectedItems,
    showButtons = true,
    icon = null,
    restricted = false
}) {
    const selected = selectedItems ?? [];
    // Let's sort the list items so that first will be the ones user has at least read privileges.
    const sortedList = restricted && listItems && listItems.length > 0 ?
        [...listItems.filter(i => i.userRole > Role.NONE), ...listItems.filter(i => i.userRole < Role.READER)]
        : listItems;
    const allowedEntitiesForUser = sortedList && sortedList.length > 0 && restricted ?
        sortedList.filter(i => i.userRole > Role.NONE).map(i => i.id) :
        (restricted ? [] : sortedList.map(i => i.id));
    const selectedStates = sortedList ? sortedList.reduce((allStates, item) => (
        {
            ...allStates,
            [item.id]: selected.includes(item.id) && allowedEntitiesForUser.includes(item.id) ? true : false
        }), {}
    ) : {};
    const allSelected = allChecked(selectedStates, restricted, allowedEntitiesForUser); //console.log('allSelected', allSelected, typeof allSelected);
    const [checkedState, setCheckedState] = useState(selectedStates);
    const [showSelectAll, setShowSelectAll] = useState(!allSelected);//console.log('showSelectAll', showSelectAll);
    
    const getCheckedItems = (list) =>{
        var idList = Object.keys(list).filter(key => list[key] === true).map(Number);
        //console.log('selected ids', idList);
        return idList;
    }

    const setActive = (e) => {
        var key = e.target.value;
        //console.log('Selected item', key);
        //console.log('this', e.target)
        //console.log('Is checked', e.target.checked);
        
        let newStates = {...checkedState};
        newStates[key] = e.target.checked;
        setCheckedState(newStates);
        setShowSelectAll(!allChecked(newStates, restricted, allowedEntitiesForUser));
        onItemSelected(getCheckedItems(newStates));
    }

    const selectAllCheckboxes = isSelected => {
        //console.log('selectAllCheckboxes', isSelected);
        let newStates = { ...checkedState };
        if (restricted && isSelected) {
            // Select only entities that user has at least reader right
            if (allowedEntitiesForUser && allowedEntitiesForUser.length > 0) {
                allowedEntitiesForUser.forEach(checkbox => { newStates[checkbox] = isSelected; });
            }
        }
        else {
            Object.keys(newStates).forEach(checkbox => { newStates[checkbox] = isSelected; });
        }
        setCheckedState(newStates);
        onItemSelected(getCheckedItems(newStates));
        setShowSelectAll(!isSelected);
      };
    
    const selectAll = () => selectAllCheckboxes(true);

    const deselectAll = () => selectAllCheckboxes(false);

    const EntitySymbol = ({ entity }) => {
        if (!entity?.factorySymbol) {
            return ("");
        }

        return (<span className="me-2 factorySymbol">{entity.factorySymbol}</span>);
    }

    const IconRow = ({ entity, showSymbols }) => {
        if (!showSymbols) {
            return ("");
        }

        if (entity.userRole < Role.READER) {
            return (
                <span className="iconList">
                    <EntitySymbol entity={entity} />
                    <img src="./icons/access_locked.svg" className="icon icon-sidebar locked me-1" title="Locked" alt="" />
                </span>
            );
        }

        if (entity.userRole < Role.CONTRIBUTOR) {
            return (
                <span className="iconList">
                    <EntitySymbol entity={entity} />
                    <img src="./icons/access_readonly.svg" className="icon icon-sidebar readonly me-1" title="Read-only access" alt="" />
                </span>
            );
        }

        return (
            <span className="iconList">
                <EntitySymbol entity={entity} />
                <img src="./icons/access_write.svg" className="icon icon-sidebar write me-1" title="Write access" alt="" />
                {
                    entity.userPublishingRole === PublishingRole.STAGE &&
                    <img src="./icons/access_stage.svg" className="icon icon-sidebar stage me-1" title="Can publish to stage" alt="" />
                }
                {
                    entity.userPublishingRole === PublishingRole.PROD &&
                    <>
                        <img src="./icons/access_stage.svg" className="icon icon-sidebar stage me-1" title="Can publish to stage" alt="" />
                        <img src="./icons/access_production.svg" className="icon icon-sidebar prod me-1" title="Can publish to production" alt="" />
                    </>
                }
            </span>
        );
    }

    const CheckBoxText = ({ entity, showSymbols }) => {
        if (entity.code) { 
            return (
                <div className="d-flex flex-column ms-2 mb-2">
                    <span className="list-group-item-heading">{entity.code}</span>
                    <span className="list-group-item-text">{entity.name ?? entity.description}</span>
                    <IconRow entity={entity} showSymbols={showSymbols} />
                </div>
                );
        }
        return (
            <div className="d-flex flex-column ms-2 mb-2">
                <span className="list-group-item-text">{entity.name ?? entity.description}</span>
                <IconRow entity={entity} showSymbols={showSymbols} />
            </div>
        );
    }

    return (
        <>
            {sortedList?.length > 0 && showButtons &&
                <ListHeader title={title} icon={icon} showButtons={showButtons}>
                    {showSelectAll &&
                        <Button color="link"
                            size="sm"
                            className="ms-1 pb-0 pt-2 pe-2"
                            onClick={() => selectAll()}
                        >
                            Select all 
                        </Button>
                    }
                    {!showSelectAll &&
                        <Button color="link"
                            size="sm"
                            className="ms-1 pb-0 pt-2 pe-2"
                            onClick={() => deselectAll()}
                        >
                            Deselect all 
                        </Button>
                    }
                </ListHeader>
            }
            {(!showButtons || !sortedList || sortedList.length === 0) &&
                <ListHeader title={title} icon={icon} showButtons={showButtons}></ListHeader>
            
            }
            <Container fluid={true} className="checkboxlist m-2 mt-0 ms-3 me-3 me-lg-0 p-3">
                {sortedList && sortedList.map((item, index) =>
                    <React.Fragment key={item.id}>
                        {restricted && index > 0 && sortedList[index - 1].userRole >= Role.READER && item.userRole < Role.READER &&
                            <hr></hr>}
                        <FormGroup 
                            className={restricted && item.userRole < Role.READER ? "form-group form-check disabled" : "form-group form-check"}
                        >
                            <Input type="checkbox"
                                id={title + "_check_" + item.id}
                                value={item.id}
                                onChange={setActive}
                                checked={checkedState[item.id]}
                                className="flex-shrink-0"
                                disabled={restricted && item.userRole < Role.READER }
                            />
                            <Label check
                                className={restricted && item.userRole < Role.READER ? "disabled" : ""} for={title + "_check_" + item.id}>
                                <CheckBoxText entity={item} showSymbols={restricted} />
                            </Label>
                        </FormGroup>
                    </React.Fragment>
                )}
            </Container>
        </>
    );
}

export default CheckboxList;