import React, { useState, useEffect } from 'react';
import {useForm, Controller} from "react-hook-form";
import { useSelector } from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { CustomSelect } from '../../common/CustomSelect';
import { SelectWithStyles } from '../../common/CustomSelect';
import { inputTypes, getUnitOptions } from '..';
import { Section, UserInputType, Action, RevisionStatus, Role } from '../../../helpers/enums';
import { getFactoryOptions, getSelectedOption } from '../../../helpers/commonHelpers';
import TextareaAutosize from 'react-textarea-autosize';
import EntityModalBase from './EntityModalBase';

const modalSchema = yup.object().shape({
    factory: yup.object().shape({ value: yup.number().required('Select a Factory') }).nullable().required('Select a Factory'),
    code: yup.string().label('Code').required().max(50),
    description: yup.string().label('Description').required().max(50),
    type: yup.object().shape({ value: yup.number().required('Select a Type') }).nullable().required('Select a Type'),
    value: yup.mixed().label('Value').required().test(
        'is-right-type',
        "Value is not of right type",
        function(value){
            //console.log('value', value);console.log('this.parent.value', this.parent.value); //console.log('this.parent.type', this.parent.type);
            var type = this.parent.type ? this.parent.type.value : -1; //console.log('type', type);
            switch(type){
                case UserInputType.INTEGER:
                    let integerSchema = yup.number().integer();
                    return integerSchema.isValid(value);
                case UserInputType.FLOAT:
                    let floatSchema = yup.number();
                    return floatSchema.isValid(value);
                default:
                    let valueSchema = yup.string().required();
                    return valueSchema.isValid(value);
            }
        }
    ),
    unit: yup.object().label('Unit').shape({ value: yup.number() }).nullable()
        .when('hasUnit', {
            is: true,
            then: yup.object().shape({ value: yup.number().required('Select a Unit') }).nullable().required('Select a Unit')
        }),
    //unit: yup.object().label('Unit').when(['hasUnit'], {
    //    is: true,
    //    then: (schema) => schema.defined('Select a Unit') 
    //}),
    internalId: yup.string().label('Internal ID').max(255),
    internalNotes: yup.string().label('Intenal notes').max(1000),
    comments: yup.string().label('Comments').max(1000)
        .when('notFirstVersion', {
            is: true,
            then: yup.string().required()
        })
});

export default function ParameterModal({
    isOpen, 
    toggle, 
    status,
    selectedParameter, 
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}){
    const stateProperty = Section.WORKSPACE;

    const createNewRevision = status === Action.EDIT && selectedParameter?.id > 0
        && (selectedParameter?.stageStatus === RevisionStatus.LATEST_REVISION || selectedParameter?.prodStatus === RevisionStatus.LATEST_REVISION)
        ? true : false;
    const notFirstVersion = status === Action.EDIT && selectedParameter?.id > 0
        && (selectedParameter?.stageStatus > RevisionStatus.NO_REVISION || selectedParameter?.prodStatus > RevisionStatus.NO_REVISION);
    
    const selectedFactoryIds = useSelector(state => state[stateProperty].selectedFactories);
    const factories = useSelector(state => state[stateProperty].factories);
    const units = useSelector(state => state[stateProperty].storageUnits);
    let selectedFactories = factories ? factories.filter(f => selectedFactoryIds.includes(f.id)) : [];//console.log('selectedFactories', selectedFactories);
    //console.log('selectedParameter', selectedParameter);
    const [hasUnit, setHasUnit] = useState(selectedParameter?.unit?.length > 0 ? true : false);
    const factoryOptions = getFactoryOptions(selectedFactories, status === Action.VIEW ? Role.READER : Role.CONTRIBUTOR);
    const unitOptions = getUnitOptions(units);

    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue, clearErrors, trigger } = useForm({ resolver: yupResolver(modalSchema), mode: 'onChange'});
    //console.log('errors, hasUnit, isValid', errors, hasUnit, isValid, getValues());
    let defaultValues = { 
        id: 0, 
        factory: factoryOptions.length === 1 ? factoryOptions[0] : [],
        code: "",
        description: "",
        type: [],
        value: "",
        unit: [],
        internalNotes: "",
        internalId: "",
        comments: "",
        notFirstVersion: notFirstVersion
    };

    const getData = (data) => {
        return {
            id: data.id,
            code: data.code,
            description: data.description,
            typeId: data.type.value,
            factoryId: data.factory.value,
            value: data.value,
            unitId: data.unit ? data.unit.value : null,
            internalNotes: data.internalNotes,
            internalId: data.internalId,
            comments: data.comments,
            storageId: data.storageId
        }
    }

    const hasUnitChanged = (e) => {
        var isChecked = e.target.checked;//console.log('has unit is changed', isChecked, getValues());
        setHasUnit(isChecked);
        setValue('hasUnit', isChecked);
        if (!isChecked){
            setValue('unit', []);
        }
    }

    const handleCodeChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('code', input.target.value);
        trigger('code');
    }

    useEffect(() => {
        if (selectedParameter && selectedParameter.id > 0) {
            if (status !== Action.COPY) {
                setValue('id', selectedParameter.id);
            }
            setValue('code', selectedParameter.code);
            setValue('factory', getSelectedOption(factoryOptions, selectedParameter.factoryId));
            setValue('description', selectedParameter.description);
            setValue('type', { value: selectedParameter.typeId, label: selectedParameter.type });
            setValue('value', selectedParameter.value);
            if (selectedParameter.unitId && selectedParameter.unitId > 0) {
                setValue('unit', getSelectedOption(unitOptions, selectedParameter.unitId));
                setHasUnit(true);
                setValue('hasUnit', true);
            }
            else {
                setValue('unit', []);
                setHasUnit(false);
                setValue('hasUnit', false);
            }
            setValue('internalNotes', selectedParameter.internalNotes ?? "");
            setValue('internalId', selectedParameter.internalId ?? "");
            if (!createNewRevision && status !== Action.COPY) {
                setValue('comments', selectedParameter.comments ?? ""); // If new revision is going to be created the comments field should be left empty!
            }
            else {
                setValue('comments', "");
            }
            setValue('notFirstVersion', notFirstVersion);
            trigger();
        }
        else {
            setValue('id', 0);
            setValue('code', "");
            setValue('factory', factoryOptions.length === 1 ? factoryOptions[0] : []);
            setValue('description', "");
            setValue('type', []);
            setValue('value', "");
            setValue('unit', []);
            setHasUnit(false);
            setValue('hasUnit', false);
            setValue('internalNotes', "");
            setValue('internalId', "");
            setValue('comments', "");
            setValue('notFirstVersion', notFirstVersion);
        }
    }, [selectedParameter, status]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <EntityModalBase
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            className="modal-lg"
            titleBase="parameter"
            baseUrl="parameter"
            getData={getData}
            createNewRevision={createNewRevision}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <Controller
                name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <Controller name="notFirstVersion" control={control} defaultValue={defaultValues.notFirstVersion}
                render={({ field }) => <Input {...field} type="hidden" />}
            />
            <div className="border-bottom mb-2">
                <h4>Owning factory</h4>
            </div>
            <FormGroup row className="required form-group">
                <Label for="factory" sm={3}>Factory</Label>
                <Col sm={9}>
                    <Controller
                        name="factory"
                        control={control}
                        defaultValue={defaultValues.factory}
                        render={({ field }) => 
                            <SelectWithStyles {...field}
                                options={factoryOptions}
                                isDisabled={(status === Action.EDIT && selectedParameter?.allReferences > 0) || status === Action.VIEW}
                            />
                        }
                    />
                    {errors.factory && <p className="text-danger">{errors.factory.message}</p>}
                </Col>
            </FormGroup>
            <div className="border-bottom mb-2 mt-4">
                <h4>General</h4>
            </div>
            <FormGroup row className="required form-group">
                <Label for="code" sm={3}>Param. code</Label>
                <Col sm={9}>
                    <Controller
                        name="code"
                        control={control}
                        defaultValue={defaultValues.code}
                        render={({ field }) =>
                            <Input {...field}
                                type="text"
                                disabled={status === Action.EDIT && selectedParameter?.allReferences > 0}
                                onChange={handleCodeChange}
                            />
                        }
                    />
                    {errors.code && <p className="text-danger">{errors.code.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="description" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={defaultValues.description}
                        render={({ field }) =>
                            <Input {...field} type="text" />
                        }
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="type" sm={3}>Type</Label>
                <Col sm={5} md={5}>
                    <Controller
                        name="type"
                        control={control}
                        defaultValue={defaultValues.type}
                        render={({ field }) => 
                            <CustomSelect {...field}
                                options={inputTypes()}
                                isDisabled={status === Action.VIEW}
                            />
                        }
                    />
                    {errors.type && <p className="text-danger">{errors.type.message}</p>}
                </Col>
            </FormGroup>
            {/*<FormGroup row className="required form-group">*/}
            {/*    <Label for="value" sm={3}>Value custom simple</Label>*/}
            {/*    <Col sm={9}>*/}
            {/*        <div className={status === Action.VIEW ? "container-editor form-control disabled" : "container-editor form-control"}>*/}
            {/*            <Controller*/}
            {/*                name="value"*/}
            {/*                control={control}*/}
            {/*                defaultValue={defaultValues.value}*/}
            {/*                render={({ field }) =>*/}
            {/*                    <CustomSimpleEditor  {...field}*/}
            {/*                        disabled={status === Action.VIEW}*/}
            {/*                    />*/}
            {/*                }*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        {errors.value && <p className="text-danger">{errors.value.message}</p>}*/}
            {/*    </Col>*/}
            {/*</FormGroup>*/}
            <FormGroup row className="required form-group">
                <Label for="value" sm={3}>Value</Label>
                <Col sm={9}>
                    <Controller
                        name="value"
                        control={control}
                        defaultValue={defaultValues.value}
                        render={({ field }) =>
                            <TextareaAutosize {...field}
                                maxRows={10}
                                minRows={2}
                                className="form-control"
                            />
                        }
                    />
                    {errors.value && <p className="text-danger">{errors.value.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="hasUnit" sm={3}>Has unit</Label>
                <Col sm={9}>
                    <Controller
                        name="hasUnit"
                        control={control}
                        defaultValue={hasUnit}
                        render={({ field }) =>
                            <Input {...field} type="checkbox" onChange={hasUnitChanged} checked={hasUnit} />
                        }
                    />
                </Col>
            </FormGroup>
            { hasUnit &&
                <FormGroup row className="required form-group">
                    <Label for="unit" sm={3}>Unit</Label>
                    <Col sm={9}>
                        <Controller
                            name="unit"
                            control={control}
                            defaultValue={defaultValues.unit}
                            render={({ field }) =>
                                <SelectWithStyles {...field}
                                    options={unitOptions}
                                    isClearable
                                    isDisabled={status === Action.VIEW}
                                />
                            }
                        />
                        {errors.unit && <p className="text-danger">{errors.unit.message}</p>}
                    </Col>
                </FormGroup>
            }
            <div className="border-bottom mb-2 mt-4">
                <h4>Additional info</h4>
            </div>
            <FormGroup row className="form-group">
                <Label for="internalNotes" sm={3}>Internal notes</Label>
                <Col sm={9}>
                    <Controller
                        name="internalNotes"
                        control={control}
                        defaultValue={defaultValues.internalNotes}
                        render={({ field }) =>
                            <TextareaAutosize {...field} maxRows={10} minRows={2} className="form-control" />
                        }
                    />
                    {errors.internalNotes && <p className="text-danger">{errors.internalNotes.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="internalId" sm={3}>Internal ID</Label>
                <Col sm={9}>
                    <Controller
                        name="internalId"
                        control={control}
                        defaultValue={defaultValues.internalId}
                        render={({ field }) =>
                            <Input {...field} type="text" />
                        }
                    />
                    {errors.internalId && <p className="text-danger">{errors.internalId.message}</p>}
                </Col>
            </FormGroup>
            <div className="border-bottom mb-2 mt-4">
                <h4>Revision info</h4>
            </div>
            <FormGroup row className={notFirstVersion ? "required form-group" : "form-group"}>
                <Label for="comments" sm={3}>Comments</Label>
                <Col sm={9}>
                    <Controller
                        name="comments"
                        control={control}
                        defaultValue={defaultValues.comments}
                        render={({ field }) =>
                            <TextareaAutosize {...field} maxRows={10} minRows={2} className="form-control" />
                        }
                    />
                    {errors.comments && <p className="text-danger">{errors.comments.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    )
}