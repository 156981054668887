import React, { useState, useEffect } from 'react';
import { CustomTable } from '../../helpers/tableHelpers';
import {
    useReactTable,
    getCoreRowModel,
    flexRender
} from '@tanstack/react-table'

export default function CustomTableWithEditableCells({
    tableColumns,
    tableData,
    className,
    updateCellData}) {
    //console.log('CustomTableWithEditableCells', tableData, typeof tableData);
    const [data, setData] = useState(() => []);
    const [columns] = useState(() => [...tableColumns]);
    const tableClasses = className ? className + " table-editable-cells mt-3" : "table-editable-cells mt-3";

    const table = useReactTable({
        data,
        columns,
        getRowId: row => row.id,
        getCoreRowModel: getCoreRowModel(),
        meta: {
            updateCellData: (rowIndex, columnId, value) => {
                setData((old) => old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value
                        };
                    }
                    return row;
                }));
                if (updateCellData) {
                    updateCellData(rowIndex, columnId, value);
                }
            }
        }
    });

    useEffect(() => {
        setData([...tableData]);
    }, [tableData]);

    return(
        <CustomTable
            table={table}
            render={flexRender}
            tableClasses={tableClasses}
            size="sm"
            enableSorting={false}
            showColumnFilters={false}
        />
    );
}