import { EntityType } from './enums';

export const checkAndGetPopulateProperties = (params) => {
    if (!params || !params.entityType || !params.storageId || params.storageId === 0) {
        return { canPopulate: false, infoMsg: "Internal error: entity and storage needs to be set!" };
    }

    let result = { canPopulate: false };
    switch (params.entityType) {
        case EntityType.ITEM:
        case EntityType.CATEGORY:
        case EntityType.CLIENTINTERFACE:
        case EntityType.ELEMENTTYPE:
        case EntityType.UNITGROUP:
            result.canPopulate = true;
            result.url = "storage/" + params.storageId;// + "?useOld=true";
            break;
        case EntityType.ELEMENT:
            if (params.items?.length > 0 &&
                params?.factories.length > 0 &&
                params?.elementTypes.length > 0
            ) {
                result.canPopulate = true;
                result.url = "items?itemIds=" + params.items.map(i => i.id).join(';') +
                    "&factoryIds=" + params.factories.join(';') +
                    "&types=" + params.elementTypes.join(';') + 
                    (params.items.some(i => i.isConfig) ? "&conf=true" : "&conf=false") +
                    "&storageId=" + params.storageId;
            }
            else if (params?.items.length > 0 && params.items.some(i => i.isConfig) &&
                params?.factories.length > 0
            ) {
                result.canPopulate = true;
                result.url = "items?itemIds=" + params.items.map(i => i.id).join(';') +
                    "&factoryIds=" + params.factories.join(';') +
                    "&conf=true" +
                    "&storageId=" + params.storageId;
            }
            else {
                result.infoMsg = "Please select at least one factory and one element type to display elements.";
            }
            break;
        case EntityType.PARAMETER:
        case EntityType.VALUETYPE:
            if (params?.factories.length > 0) {
                result.canPopulate = true;
                result.url = "storage/" + params.storageId +
                    "?factoryIds=" + params.factories.join(';');
            }
            else {
                result.infoMsg = "Please select at least one factory to display " +
                    (params.entityType === EntityType.PARAMETER ? "parameters." : "value types.");
            }
            break;
        case EntityType.UNIT:
            if (params?.unitGroups.length > 0) {
                result.canPopulate = true;
                // We need to always populate all units (to refresh the storage units within Redux)
                // But after populating lets filter and return only the needed units (by given unit groups)
                result.url = "storage/" + params.storageId;
                result.filterEntities = function (storageUnits) {
                    if (!storageUnits || storageUnits.length === 0) {
                        return storageUnits;
                    }
                    return storageUnits.filter(u => params.unitGroups.includes(u.groupId));
                }
            }
            else {
                result.infoMsg = "Please select at least one unit group to display units.";
            }
            break;
        default:
            break;
    }

    return result;
}