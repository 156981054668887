import React, { useState } from 'react';
import { EntityStatus, RevisionStatusText, BoolenText, UserInputType } from "../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import { Input, Button } from 'reactstrap';
import {
    getValueString,
    getCellDiv,
    getEmptyCellDiv,
    getValueCellDiv,
} from '../../helpers/commonHelpers';
import Moment from 'react-moment';
import moment from 'moment';
import { useSortable } from '@dnd-kit/sortable'
import TextareaAutosize from 'react-textarea-autosize';

// <Private methods>

const getValueAsText = (value) => {
    if (!value && typeof value != "boolean") {
        return "";
    }

    if (typeof value === "boolean") {
        return value === true ? BoolenText.TRUE : BoolenText.FALSE;
    }

    return value;
}

const getTextValue = ({ value }) => {
    return getValueAsText(value);
}


const dataContainsSeveralRows = (rowCount, id) => {
    if (!id || !rowCount || rowCount < 2) {
        return false;
    }

    return true;
}

const isLastRow = (rowCount, index) => {
    if (index === rowCount - 1) {
        return true;
    }

    return false;
}

const isDOMTypeElement = (element) => {
    return React.isValidElement(element) && typeof element.type === 'string';
}

const getDeepKeyValue = (obj, keys) => {
    if (obj) {
        if (Array.isArray(keys)) {
            if (keys?.length > 0) {
                //console.log('obj, keys', obj, keys);
                if (keys[0] in obj) {
                    //let value = obj[keys[0]];
                    if (keys.length === 1) {
                        return obj[keys[0]];
                    }
                    const newObj = obj[keys[0]];
                    keys.shift();
                    return getDeepKeyValue(newObj, keys);
                }
            }
            return "";
        }
        if (keys?.length > 0 && keys in obj) {
            return obj[keys];
        }
    }
    return obj;
}

const getRevisionPropertyValue = (revision, columnId) => {
    if (columnId.includes('_')) {
        const keys = columnId.split('_');
        return getDeepKeyValue(revision, keys);
    }
    return revision[columnId];
}

const getRevisionBaseData = (revision, columnId, maxLength, symbolId, getMethod, getCellMethod) => {
    if (!revision) {
        return { id: -1, value: null };
    }
    const value = getMethod ?
        getMethod({ value: getRevisionPropertyValue(revision, columnId), revisionData: revision, maxLength: maxLength }) :
        getValueAsText(getRevisionPropertyValue(revision, columnId));

    const data = {
        id: revision.revisionId,
        value: value,
        cellValue: getCellMethod ? getCellMethod({ value: value, columnId: columnId, revisionData: revision, customClass: null, maxLength: maxLength}) : value
    };
    if (symbolId) {
        return { ...data, symbol: revision[symbolId] };
    }
    return data;
}

const getRevisionValueDataForElement = (revision, columnId, maxLength) => {
    //console.log('getRevisionValueDataForElement', revision, columnId, maxLength);
    if (!revision) {
        return { id: -1, value: null };
    }

    const error = revision.evaluationOk === false;
    const originalValue = revision[columnId]; //console.log('originalValue', originalValue);

    return {
        id: revision.revisionId,
        orgValue: originalValue,
        value: getValueString(originalValue),
        cellValue: getValueCellDiv(originalValue, error ? "alert-danger" : "", maxLength),
        error: error
    };
}

const isSameRevision = (current, stage, prod) => {
    const currentId = current?.revisionId ?? current?.id ?? -1;
    const stageId = stage?.revisionId ?? stage?.id ?? -1;
    const prodId = prod?.revisionId ?? prod?.id ?? -1;
    if ((stageId === currentId || stageId === -1) && (prodId === currentId || prodId === -1)) {
        return true;
    }
    return false;
}

const setAndCheckValuesAreSameInAllRevisions = (current, stage, prod) => {
    try {
        // Set the values empty if null or undefined or to corresponding text value for boolean
        stage.value = getValueAsText(stage.value);
        prod.value = getValueAsText(prod.value);

        stage.isSame = current.value.localeCompare(stage.value) === 0;
        prod.isSame = current.value.localeCompare(prod.value) === 0;
        // Values are the same if 
        // current and stage values are the same and there is no prod revision or 
        // current and  prod values are the same and there is no stage revision or
        // all values in work, stage and prod are the same
        if ((stage.isSame && prod.id <= 0) || (prod.isSame && stage.id <= 0) || (stage.isSame && prod.isSame)) {
            return true;
        }

        return false;
    }
    catch (error) {
        console.log('error occured', error);
        return true;
    }
}

const areProdAndStageValuesSame = (stage, prod, checkIdAndValue = false) => {
    //console.log('stage, prod', stage, prod);
    if (checkIdAndValue) {
        if ((prod.id === stage.id && prod.id > 0 &&
            prod.value && stage.value
                && prod.value.toString().localeCompare(stage.value.toString()) === 0)
            || (!prod.value && !stage.value)) {
            return true;
        }
    }
    else if ((prod.id === stage.id && prod.id > 0)
        || (prod.value && stage.value
            && prod.value.toString().localeCompare(stage.value.toString()) === 0)
        || (!prod.value && !stage.value)) {
        return true;
    }
    return false;
}

const getChangesCellDiv = (cellData, id = "value") => {
    try {
        if (!cellData) {
            return "";
        } //console.log('cellData', cellData);
        const cellValue = cellData[id];
        let className = "";
        if (cellData.className && cellData.className.lenght > 0) {
            className = cellData.className;
        }
        if (!cellValue || cellValue.length === 0) {
            return (<div className={className}></div>)
        }

        if (isDOMTypeElement(cellValue) && cellValue.props.hasOwnProperty('className') &&
            cellValue.props.className !== null && cellValue.props.className.length > 0) {
            if (cellValue.props.hasOwnProperty('children')) {
                // Let's combine the div element with set classes
                return (<div className={className + " " + cellValue.props.className}>{cellValue.props['children']}</div>)
            }
            return (<div className={className}>{cellValue}</div>)
        }
        return (<div className={className}>{cellValue}</div>)
    }
    catch (error) {
        console.log('Error: ', error);
        return "";
    }
}

const DifferenceDiv = ({value, id}) => {
    return (<div className="bg-warning">{getCellDiv({ value: value, columnId: id})}</div>);
}

const DifferenceDivWithSymbol = ({ value, symbol }) => {
    return (<CellWithSymbol value={value} symbol={symbol} className="bg-warning" />);
}

const BaseChangesLogCell = (
    initialValue,
    index,
    id,
    table,
    getValueMethod,
    row,
    compareMethod
) => {
    //console.log('id, table:', id, table);//.getRowCount(), table.getCoreRowModel())
    const currentValue = getValueMethod({ value: initialValue, revisionData: row });
    //if (id === 'valueType.code' || id === 'criterion') {
    //    console.log('initial and current values', initialValue, currentValue);
    //}
    // If there is no more than one row within the data or it is the last row the value itself is returned
    const rowCount = table.getRowCount();
    if (!dataContainsSeveralRows(rowCount, id) || isLastRow(rowCount, index)) {
        return getCellDiv({ value: currentValue, columnId: id });
    }

    const data = table.getCoreRowModel().flatRows;
    
    const previousValueRow = data[index + 1]?.original;
    if (!previousValueRow){
        return getCellDiv({ value: currentValue, columnId: id });
    }
    const previousValue = getValueMethod({ value: getRevisionPropertyValue(previousValueRow, id), revisionData: previousValueRow });
    //if (id === 'valueType_code'){
    //    console.log('currentValue and previous values', currentValue, previousValue);
    //}

    if (compareMethod){
        if (compareMethod(row, previousValueRow)){
            return getCellDiv({ value: currentValue, columnId: id }); // The current and previous values are the same
        }
        else{
            return (<DifferenceDiv value={currentValue} id={id}/>);
        }
    }
    if (currentValue.localeCompare(previousValue) === 0){
        return getCellDiv({ value: currentValue, columnId: id }); // The current and previous values are the same
    }
    return (<DifferenceDiv value={currentValue} id={id}/>); // Current values has changed
}

const BaseChangesCell = (
    current,
    stage,
    prod,
    stageAndProdValuesAreSame,
    id = "value"
) => { 
    current.status = ["W"];
    stage.status = [];
    prod.status = [];
    
    if (stage.isSame && stage.id !== -1) {
        current.status = [...current.status, "S"];
    }
    else {
        stage.status = ["S"];
    }
    if (prod.isSame && prod.id !== -1){
        current.status = [...current.status, "P"];
    }
    else{
        if (stageAndProdValuesAreSame){
            stage.status = [...stage.status, "P"];
        }
        else if (prod.id !== -1 && prod[id] !== null){
            prod.status = ["P"];
        }
    }
    
    const EnvCell = ({ statuses, className }) => {
        return (<div className={"splitCellEnv " + className}>
            {
                statuses?.includes('W') &&
                <img src="./icons/access_write.svg" className="icon icon-sidebar write" title="Value in work environment" alt="" />
            }
            {
                statuses?.includes('S') &&
                <img src="./icons/access_stage.svg" className="icon icon-sidebar stage" title="Value in stage environment" alt="" />
            }
            {
                statuses?.includes('P') &&
                <img src="./icons/access_production.svg" className="icon icon-sidebar prod" title="Value in production environment" alt="" />
            }
        </div>)
    }

    const SplitCell = ({ entity, className }) => {
        //console.log('id, entity', id, entity);
        if (entity.status?.length > 0) {
            return (
                <>
                    <div className={(entity.error ? "alert-danger " : "") + "splitCellValue " + className}>
                        {entity.symbol ?
                            <CellWithSymbol value={entity[id]} symbol={entity.symbol} /> :
                            getChangesCellDiv(entity, id)}
                    </div>
                    <EnvCell statuses={entity.status} className={className} />
                </>
            );
        }

        return "";
    }
    
    return (
        <div className="splitCellWrapper">
            <SplitCell entity={current} className="latest" />
            <SplitCell entity={stage} className="earlier splitBorder" />
            <SplitCell entity={prod} className="earlier splitBorder" />
        </div>
    );
}

const BaseDateCell = ({date, longFormat = false}) => { 
    if (!date){
        return getEmptyCellDiv();
    }
    
    moment.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: '%ds',
            ss: '%ds',
            m: '1m',
            mm: '%dm',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1mo',
            MM: '%dmo',
            y: '1y',
            yy: '%dy'
        }
    });
    const dateDiff = moment().diff(date, 'days', false); //console.log('dateDiff for ' + date, dateDiff, moment.locale());
    
    let className =
        (dateDiff < 1 ? "from-now highlightTag within1d text-minor" :
            (dateDiff < 7 ? "from-now highlightTag within7d text-minor" :
                "from-now text-minor"));

    return (
        <div className="d-flex flex-column">
            <span className="text-nowrap">
                <img src="./icons/calendar.svg" className="icon icon-time pe-1" alt="" />
                <Moment date={date} format='YYYY-MM-DD' className="text-minor pe-3" />
            </span>
            {longFormat &&  
                <span className="text-nowrap">
                        <img src="./icons/clock.svg" className="icon icon-time pe-1" alt="" />
                        <Moment date={date} format='HH:mm' className="text-minor" />
                    </span>
            }
            <span className="text-nowrap">
                <Moment fromNow className={className}>{date}</Moment>
            </span>
        </div>
    );
}

const defaultCompareMethod = (value1, value2) => {
    //console.log('value1, value2', value1, value2);
    if (!value1 && !value2) {
        return true;
    }

    if ((!value1 && value2) || (value1 && !value2)) {
        return false;
    }

    //console.log('value1: "' + value1 + '",  value2: "' + value2 + '"');
    return value1.localeCompare(value2) === 0;
}

const CurrentCell = ({ current }) => {
    if (current.symbol) {
        return (<CellWithSymbol value={current.value} symbol={current.symbol} />);
    }
    return current.cellValue;
}

// </Private methods>

export const CellWithSymbol = ({
    value,
    symbol,
    className
}) => {
    return (
        <div className={(className ? className + " " : "") + "d-flex flex-column"}>
            <span>{value}</span>
            {symbol && <span>{symbol}</span>}
        </div>
    );
}

export const StatusCellSimple = ({
    getValue
}) => {
    const initialValue = getValue();
    if (!initialValue || initialValue.length === 0){
        return (<div className="bg-secondary text-custom-light">{BoolenText.FALSE}</div>);
    }

    return (<div className="bg-success text-custom-light">{BoolenText.TRUE}</div>);
}

export const StatusCell = ({
    getValue
}) => {
    const initialValue = getValue();
    if (initialValue === RevisionStatusText.LATEST_REVISION){
        return (<div className="bg-success text-custom-light">{initialValue}</div>);
    }

    if (initialValue === RevisionStatusText.EARLIER_REVISION){
        return (<div className="bg-warning">{initialValue}</div>);
    }
    return (<div className="bg-secondary text-custom-light">{initialValue}</div>);
}

export const ChangesCell = ({
    getValue,
    row: { original },
    column: { id },
    maxLength = 0
}) => {
    return (<RevisionChangesCell value={getValue()}
        row={{ original: original }}
        column={{ id: id }}
        getCellMethod={getCellDiv}
        compareValues={true}
        maxLength={maxLength}
    />);
}

export const RevisionChangesCell = ({
    value,
    row: { original },
    column: { id },
    getMethod,
    getCellMethod,
    compareMethod,
    compareValues = false,
    symbolId = null,
    maxLength = 0
}) => {
    if (!original) {
        return value;
    }
    //console.log('value, original, id', value, original, id);
    const current = getRevisionBaseData(original, id, maxLength, symbolId, getMethod, getCellMethod);
    const revisions = original ? original.revisions : null;
    
    if (!revisions || revisions.length === 0 || !current.id) {//console.log('No revisions found for ' + original.code);
        return (<CurrentCell current={current} />);
    }

    const stageRevision = revisions.find(obj => { return obj.status === EntityStatus.STAGE });
    const prodRevision = revisions.find(obj => { return obj.status === EntityStatus.PROD });

    if (isSameRevision(original, stageRevision, prodRevision)) {
        // All the revisions are the same
        return (<CurrentCell current={current} />);
    }

    // Get revision id and value for stage and production
    const stage = getRevisionBaseData(stageRevision, id, maxLength, symbolId, getMethod, getCellMethod);
    const prod = getRevisionBaseData(prodRevision, id, maxLength, symbolId, getMethod, getCellMethod); //console.log('current, stage, prod', current, stage, prod);

    //console.log('id, current, stage, prod', id, current, stage, prod);
    const usedCompareMethod = compareMethod ?? defaultCompareMethod;
    stage.isSame = compareValues ? usedCompareMethod(current.value, stage.value) : usedCompareMethod(original, stageRevision, id);
    prod.isSame = compareValues ? usedCompareMethod(current.value, prod.value) : usedCompareMethod(original, prodRevision, id);
    // Let's return the value itself if values are the same in stage and there is no prod revision or the values are the same in prod and there is no stage revision
    // or all values in work, stage and prod are the same
    if ((stage.isSame && prod.id <= 0) || (prod.isSame && stage.id <= 0) || (stage.isSame && prod.isSame)) {
        return (<CurrentCell current={current} />);
    }

    return (BaseChangesCell(current, stage, prod, compareValues ? usedCompareMethod(stage.value, prod.value) : usedCompareMethod(stageRevision, prodRevision, id), "cellValue"));
}

export const ElementValueCell = ({
    row: { original },
    column: { id },
    maxLength = 0
}) => {
    const current = getRevisionValueDataForElement(original, id, maxLength); //console.log('current', current);
    const revisions = original ? original.revisions : null;
    if (!revisions || revisions.length === 0 || !current.id) {//console.log('No revisions found!');
        return current.cellValue;
    }
    const stageRevision = revisions.find(obj => {return obj.status === EntityStatus.STAGE});
    const prodRevision = revisions.find(obj => {return obj.status === EntityStatus.PROD});
    let stage = getRevisionValueDataForElement(stageRevision, id, maxLength);
    let prod = getRevisionValueDataForElement(prodRevision, id, maxLength);
    //console.log('id & maxLength & original', id, maxLength, original);
    //console.log('current, stage, prod', current, stage, prod);

    const allSame = setAndCheckValuesAreSameInAllRevisions(current, stage, prod);
    if (allSame) {
        return current.cellValue;
    }

    const prodAndStageValuesAreSame = areProdAndStageValuesSame(stage, prod, true);

    return (
        BaseChangesCell(
            current,
            stage,
            prod,
            prodAndStageValuesAreSame,
            "cellValue"
            )
        );
}

export const ChangesLogCell = ({
    getValue,
    row: { index }, 
    column: { id },
    table
}) => {
    //console.log('id, getValue, data:', id, getValue(), table);
    return BaseChangesLogCell(getValue(), index, id, table, getTextValue);
}

export const CustomChangesLogCell = ({
    value,
    row: { index }, 
    column: { id },
    table,
    getMethod
}) => {
    return BaseChangesLogCell(value, index, id, table, getMethod);
}

export const RowChangesLogCell = ({
    value,
    row: {index, original},
    column: { id },
    table,
    getMethod,
    compareMethod
}) => {
    return BaseChangesLogCell(value, index, id, table, getMethod, original, compareMethod);
}

export const FactoryLogCell = ({
    value,
    row: { index, original },
    column: { id },
    table
}) => {
    const getValueIsSubst = (value, row) => {
        let currentValue = value;
        if (row && row.isSubstitutable) {
            currentValue = currentValue + " (subst.)"
        }
        return currentValue;
    }

    const currentValue = getValueIsSubst(value, original);//console.log('initial and current values', initialValue, currentValue);
    // If there is no more than one row within the data or it is the last row the value itself is returned
    const rowCount = table.getRowCount();
    if (!dataContainsSeveralRows(rowCount, id) || isLastRow(rowCount, index)) {
        return (<CellWithSymbol value={currentValue} symbol={original.factorySymbol} />);
        //getCellDiv(currentValue, id);
    }

    const data = table.getCoreRowModel().flatRows;
    const previousValueRow = data[index + 1]?.original; //console.log('currentValue, previousValueRow', currentValue, previousValueRow)
    if (!previousValueRow) {
        return (<CellWithSymbol value={currentValue} symbol={original.factorySymbol} />);
    }
    const previousValue = getValueIsSubst(previousValueRow[id], previousValueRow); //console.log('previousValue', previousValue)
    // if (id === 'input'){
    //     console.log('currentValue and previous values', currentValue, previousValue);
    // }

    if (currentValue.localeCompare(previousValue) === 0) {
        return (<CellWithSymbol value={currentValue} symbol={original.factorySymbol} />); // The current and previous values are the same
    }
    return (<DifferenceDivWithSymbol value={currentValue} symbol={original.factorySymbol} />); // Current values has changed
}

export const EditableCell = ({
    initialValue,
    index,
    id,
    type,
    table
}) => {
    const { control } = useForm();
    const [value, setValue] = useState(initialValue);

    const onBlur = () => {
        //console.log('onBlur');
        table.options.meta?.updateCellData(index, id, value);
    }

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (type === UserInputType.STRING) {
        return (<Controller name="code" control={control} defaultValue={value}
            render={({ field }) =>
                <TextareaAutosize {...field}
                    maxRows={10}
                    minRows={2}
                    className="form-control"
                    onChange={e => setValue(e.target.value)}
                    onBlur={onBlur}
                    value={value}
                />}
        />);
    }

    return (<Controller name="code" control={control} defaultValue={value}
        render={({ field }) =>
            <Input {...field}
                type="text"
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                value={value}
            />}
    />);
}

export const CheckboxCell = ({
    id,
    index,
    checked,
    disabled,
    table }) => {

    const [isChecked, setIsChecked] = useState(checked);

    return (<div className="px-1 d-flex justify-content-start">
        <Input type="checkbox"
        id={"userInput_check_" + id}
        onChange={e => {
            setIsChecked(!isChecked);
            table.options.meta?.updateCellData(index, id, !isChecked);
        }}
        checked={isChecked}
        className="flex-shrink-0"
        disabled={disabled}
        />
    </div>);
}

export const DivCell = ({
    getValue,
    column: { id },
}) => {
    return getCellDiv({ value: getValue(), columnId: id});
}

export const DateCell = ({
    getValue
}) => {
    return (
        <BaseDateCell date={getValue()} />
    );
}

export const UserAndDateCell = ({
    value,
    date
}) => {
    
    if (!value){
        return (
            <BaseDateCell date={date} longFormat={true} />
        );
    }

    return (
        <div className="d-flex flex-column">
            <span className="text-major">{value}</span>
            <BaseDateCell date={date} longFormat={true} />
        </div>
    );
}

export const ItemCell = ({
    code,
    description
}) => {
    return (
        <div>
            <span className="text-major">{code}</span>
            {getCellDiv({ value: description, columnId: "description"})}
        </div>
    );
}

export const SimpleDivCell = ({
    value,
    toLower = false,
    className = ""
}) => {
    if (value?.length > 0) {
        if (toLower) {
            return (<div className={className}>{value.toLowerCase()}</div>)
        }
        return (<div className={className}>{value}</div>);
    }

    return getEmptyCellDiv();
}

export const RowDragHandleCell = ({ rowId }) => {
    const { attributes, listeners } = useSortable({
        id: rowId,
    })
    return (
        // Optional unicode characters for the button: ⚌↕⇕⨁⬍⬇⬆🡫🡩⮃⇵🡣🡡
        <Button outline size="sm" className="border-0" onClick={(e) => e.preventDefault()} {...attributes} {...listeners}> 
            ⇵
        </Button>
    )
}