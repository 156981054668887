import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { CustomSelect } from '../../common/CustomSelect';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ActionButtonModalBase from '../../common/modals/ActionButtonModalBase';
import { Action } from '../../../helpers/enums';
import { inputWithBooleanTypes } from '..';
import { getButtonText } from '../../../helpers/commonHelpers';

const schema = yup.object().shape({
    code: yup.string().label('Code').required('Code is a required field').max(50),
    description: yup.string().label('Description').required('Description is a required field'),
    type: yup.object().shape({ value: yup.number().required('Select a Type') }).nullable().required('Select a Type')
});

export function UserInputModal({
    isOpen,
    toggle,
    status,
    existingInputs,
    selectedUserInput,
    onUserInputCreated,
    onUserInputUpdated,
    references
}){
    const [operation, setOperation] = useState(0);
    const actionButtonText = getButtonText(status);
    const { reset, formState: { errors, isValid }, control, getValues, trigger, setValue, setError } = useForm({resolver: yupResolver(schema), mode: 'onChange'});
    
    let defaultValues = { 
        id: 0, 
        uniqueId: 0,
        code: "",
        description: "",
        type: []
    };

    const types = inputWithBooleanTypes();
    //console.log('types', types);
    const disabled = references > 0;
    const modalIsClosed = () => {
        reset();
    }

    const userInputEvent = async() =>{
        const userInputValues = getValues(); //console.log('userInputValues & existingInputs', userInputValues, existingInputs);
        await trigger(["code", "description", "type"]);
        //console.log('errors', errors);
        if (!errors.code && !errors.description && !errors.type){
            const alreadyExists = existingInputs.length > 0 && existingInputs.some(i => i.uniqueId !== userInputValues.uniqueId && i.code.toLowerCase() === userInputValues.code.toLowerCase());
            if (alreadyExists) {
                setError("code", {
                    type: "manual",
                    message: userInputValues.code + " already exists. Cannot add duplicates!",
                })
            }
            else {
                //console.log('No errors!');
                if (status === Action.EDIT) {
                    onUserInputUpdated(userInputValues);
                }
                else {
                    onUserInputCreated(userInputValues);
                }
                toggle();
                modalIsClosed();
                reset(); 
            }
        }
        setOperation(operation + 1);
    }

    useEffect(() => {
        //console.log('selectedUserInput changed', selectedUserInput);
        if (selectedUserInput && selectedUserInput.id >= 0) {
            setValue('id', selectedUserInput.id);
            setValue('uniqueId', selectedUserInput.uniqueId > 0 ? selectedUserInput.uniqueId : 0);
            setValue('code', selectedUserInput.code);
            setValue('description', selectedUserInput.description);
            setValue('type', { value: selectedUserInput.typeId, label: selectedUserInput.type });
            trigger();
        }
        else { 
            // Let's empty all values
            setValue('id', 0);
            setValue('uniqueId', 0);
            setValue('code', "");
            setValue('description', "");
            setValue('type', []);
        }
    }, [selectedUserInput, status, setValue, trigger]);

    return (
        <ActionButtonModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={status + ' user input'}
            actionButtonText={actionButtonText}
            onModalIsClosing={modalIsClosed}
            onClick={userInputEvent}
            formIsValid={isValid}
            setNotLoading={operation}
        >
            <Controller name="id" control={control} defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <Controller name="uniqueId" control={control}  defaultValue={defaultValues.uniqueId}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <FormGroup row className="required form-group">
                <Label for="code" sm={3}>Code</Label>
                <Col sm={9}>
                    <Controller name="code" control={control} defaultValue={defaultValues.code}
                        render={({field}) => <Input {...field} type="text" disabled={disabled}/>}
                    />
                    {errors.code && <p className="text-danger">{errors.code.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="description" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller name="description" control={control} defaultValue={defaultValues.description}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="type" sm={3}>Type</Label>
                <Col sm={6}>
                    <Controller name="type" control={control} defaultValue={defaultValues.type} render={({field}) => 
                        <CustomSelect {...field} options={types} isDisabled={disabled} />
                        }
                    />
                    {errors.type && <p className="text-danger">{errors.type.message}</p>}
                </Col>
            </FormGroup>
        </ActionButtonModalBase>
    )
}